<template>
    <div class="headline" :style="scssVariables">
        <img
            src="@/assets/customize/uc/headline.png"
            alt=""
            width="50px"
            class="icon"
        />
        <div class="outer">
            <div class="mask">
                <div class="content">
                    <span>{{ headlineText }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        headlineText: String,
    },
    computed: {
        scssVariables() {
            return {
                '--headlineDuration': `${this.headlineDuration}s`,
            };
        },
        headlineDuration() {
            return this.headlineText.length / 2 < 10
                ? 15
                : this.headlineText.length / 2;
        },
    },
};
</script>

<style lang="scss" scoped>
$headline-duration: var(--headlineDuration);

.headline {
    width: 95%;
    margin: auto;
    display: flex;
    .icon {
        margin-right: 10px;
        height: auto;
    }
    .outer {
        flex: 1;
        width: 100%;
        background: rgba(0, 0, 0, 0.51);
        border: 1px solid #ffffff;
        border-radius: 5px;
    }
    .mask {
        position: relative;
        margin: auto;
        display: flex;
        justify-content: center;
        overflow: hidden;
        width: 98%;
        height: 100%;
    }
    .content {
        position: absolute;
        color: #ffffff;
        margin: 0;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        animation: headline $headline-duration linear infinite;
        span {
            white-space: nowrap;
        }
    }
    @keyframes headline {
        0% {
            transform: translateX(0%);
            left: 110%;
        }
        100% {
            transform: translateX(-110%);
            left: 0%;
        }
    }
}
</style>
