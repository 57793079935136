<template>
    <div class="ipad-page leader-board">
        <Headline v-if="headline" :headline-text="headlineText" />
        <div class="title">即時個案榜</div>
        <div class="inner">
            <div class="display-section">
                <SalesLeaderBoard :data="list" :header="header" />
            </div>
            <div class="tags">
                <div
                    v-for="(tag, index) in tags"
                    :key="index"
                    class="tag"
                    @click="onGoTo(tag.destination)"
                >
                    {{ tag.label }}
                </div>
            </div>
            <div class="policy-box">
                <div
                    class="item reveal-information"
                    @click="onOpenRevealInformationPopup"
                >
                    羅氏揭露訊息
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import GuardDisabled from '@/middleware/GuardDisabled';
import SalesLeaderBoard from '@/components/Table/SalesLeaderBoard.vue';
import Headline from '@/components/LeaderBoard/Headline.vue';
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
    mixins: [GuardDisabled],
    components: { SalesLeaderBoard, Headline },
    computed: {
        ...mapState({
            config: (state) => state.config.leaderBoard,
        }),
    },
    data() {
        return {
            loading: false,
            searchKeyword: '',
            list: [],
            header: [],
            tags: [],
            headline: false,
            headlineText: '',
        };
    },
    created() {
        this.init();
    },
    methods: {
        ...mapMutations({
            updateRevealInformationPopupState: 'popup/updateRevealInformationPopupState',
        }),
        ...mapActions({
            apiClient: 'api/invoke',
        }),
        onOpenRevealInformationPopup() {
            this.updateRevealInformationPopupState(true);
        },
        async init() {
            this.header = this.config.table;
            this.tags = this.config.tags;
            this.headline = this.config.headline;

            const LEADER_PAYLOAD = {
                category: 'Case',
                function: 'searchSalesDisplayCase',
                data: { query: `?limit=${5}&page=${1}` },
            };
            const LEADER_RES = await this.apiClient(LEADER_PAYLOAD);
            this.list = LEADER_RES.data.rows;

            if (this.headline) {
                const HEADLINE_PAYLOAD = {
                    category: 'Headline',
                    function: 'get',
                };
                const HEADLINE_RES = await this.apiClient(HEADLINE_PAYLOAD);
                this.headlineText = HEADLINE_RES.data.content;
            }
        },
        onGoTo(destination) {
            this.$router.push({
                path: 'case',
                query: {
                    fieldName: destination.fieldName,
                    value: destination.value,
                    column: destination.column,
                    type: destination.type,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.leader-board {
    .title {
        font-size: 50px;
        text-align: center;
    }
    .inner {
        height: fit-content;
        .display-section {
            height: 360px;
            margin-top: 20px;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
    .tags {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        width: 100%;
        margin: -15px 0 0 -0;
        margin-top: 0;
        > * {
            cursor: pointer;
            margin: 15px 0 0 0;
            border-radius: 12px;
            padding: 15px 0;
            width: 32%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
</style>
