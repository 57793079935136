<template>
    <div class="sales-leader-board-table">
        <div
            class="table-header"
            :style="{ 'grid-template-columns': gridStyle }"
        >
            <div></div>
            <div v-for="(item, index) in header" :key="index">
                {{ item.label }}
            </div>
        </div>
        <div
            v-for="(item, index) in data"
            :key="index"
            class="search-result-item"
            :style="{ 'grid-template-columns': gridStyle }"
        >
            <div class="index">{{ index + 1 }}</div>
            <div v-for="(tag, index) in header" :key="index">
                {{ getValue(item.data, tag) || "N/A" }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default: () => [],
        },
        header: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            multipleSelection: [],
            isIndeterminate: false,
            checkAll: false,
        };
    },
    computed: {
        gridStyle() {
            let STYLE = '50px';
            for (let i = 0; i < this.header.length; i += 1) {
                STYLE += ' 1fr';
            }
            return STYLE;
        },
    },
    methods: {
        getValue(data, tag) {
            if (!data) return undefined;

            const SPLIT = tag.fieldName.split('.');
            const COMBINE = (SPLIT.length > 1 && SPLIT) || undefined;

            if (COMBINE) {
                let DATA = data.data;
                COMBINE.forEach((el) => {
                    DATA = DATA[el];
                });
                return DATA;
            }
            return data.data[tag.fieldName];
        },
    },
};
</script>

<style lang="scss" scoped>
.sales-leader-board-table {
    height: 100%;
    padding: 0 20px;
    .table-header,
    .search-result-item {
        display: grid;
        justify-content: space-between;
        justify-items: center;
        align-items: center;
        padding: 10px 30px;
        > * {
            word-break: keep-all;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 5px;
        }
    }
    .index {
        width: 32px;
        height: 32px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 7px;
    }
}
</style>
